// Colors
$gray-1: #262626;
$gray-2: #434343;
$gray-3: #555555;
$gray-4: #7B7B7B;
$gray-5: #9D9D9D;
$gray-6: #C4C4C4;
$gray-7: #D9D9D9;
$gray-8: #E9E9E9;
$gray-9: #F5F5F5;

// Button
$btn-tertiary-border-color:$semi-dark;
$btn-tertiary-color:$secondary-color;

// input
$input-border-color:#DDDDDD;
$input-height:40px;
$input-font-size:14px;
$input-placeholder:#333;
$input-font-color:#000;
$input-label-font-size:14px;
$form-control-mb:20px;

//tables
$horizontal-thead-border-color: #9D9D9D;
$table-even-color: #F9F5EB;

// Menu
$small-menu-width:64px;